import axios from 'axios';
import router from '@/router';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL ?? 'https://logistics.granuband.com/api/';
axios.defaults.withCredentials = true;

export function auth(data) {
  return new Promise((resolve, reject) => {
    axios({ url: 'auth', data: data, method: 'POST' })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        if (err.response.data.error) {
          reject(err.response.data.error);
        }
        reject(err);
      });
  });
}

export function getKlantData() {
  return get('klant-data');
}

export function getOpenInzamelingen() {
  return get('open-inzamelingen');
}

export function getOpgehaaldeInzamelingen() {
  return get('opgehaalde-inzamelingen');
}

export function nieuweInzameling(data) {
  return post('nieuwe-inzameling', data);
}

export function nieuweOpmerking(data) {
  return post('nieuwe-opmerking', data);
}

export function apiLogout() {
  return get('logout');
}

function get(url) {
  return new Promise((resolve, reject) => {
    axios({ url: url, method: 'GET' })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else if (err.response && err.response.data.error) {
          reject(err.response.data.error);
        } else {
          console.log('network error: ' + err);
          reject(err);
        }
      });
  });
}

function post(url, data) {
  return new Promise((resolve, reject) => {
    axios({ url: url, data: data, method: 'POST' })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        } else if (err.response && err.response.data.error) {
          reject(err.response.data.error);
        } else {
          console.log('network error: ' + err);
          reject(err);
        }
      });
  });
}

function logout() {
  store.dispatch('logout').then(() => {
    router.push('/inloggen').catch((error) => {
      if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(error);
      }
    });
  });
}
