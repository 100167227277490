import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import Home from './views/Home';
import Inzameling from './views/Inzameling';
import Geschiedenis from './views/Geschiedenis';
import Opmerking from './views/Opmerking';
import Login from './views/Login';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/inzameling',
    name: 'Inzameling',
    component: Inzameling,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/geschiedenis',
    name: 'Geschiedenis',
    component: Geschiedenis,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/opmerking',
    name: 'Opmerking',
    component: Opmerking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/inloggen',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/inloggen');
  } else {
    next();
  }
});

export default router;
