<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <OpmerkingForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OpmerkingForm from '@/components/OpmerkingForm';

export default {
  name: 'Opmerking',

  components: {
    OpmerkingForm,
  },
};
</script>

<style scoped></style>
