<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="6" sm="8">
      <v-alert v-if="resultaat" type="success">
        {{ resultaat }}
      </v-alert>
      <v-card v-if="!resultaat && klantData" class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Verstuur opmerking</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="login">
            <v-text-field
              v-if="klantData.adressen.length <= 1"
              v-model="klantData.adressen[0].adres"
              label="Afhaaladres"
              prepend-icon="mdi-home-city-outline"
              disabled
            ></v-text-field>
            <v-select
              v-if="klantData.adressen.length > 1"
              v-model="adres"
              :rules="requiredRule"
              :items="klantData.adressen"
              item-value="id"
              item-text="adres"
              label="Afhaaladres"
              prepend-icon="mdi-home-city-outline"
            ></v-select>
            <v-select
              v-model="type"
              :rules="requiredRule"
              :items="opmerkingTypes"
              item-value="id"
              item-text="naam"
              label="Type"
              prepend-icon="mdi-alpha-b-circle-outline"
            ></v-select>
            <v-textarea v-model="opmerkingen" label="Opmerkingen" prepend-icon="mdi-comment"></v-textarea>
          </v-form>
          <v-alert v-if="errorMessage" dense text type="error">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid || loading" color="primary" @click="verstuurOpmerking">
            {{ loading ? 'Bezig met versturen...' : 'Verstuur opmerking' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { nieuweOpmerking } from '@/api';

export default {
  name: 'OpmerkingForm',
  data() {
    return {
      adres: '',
      type: '',
      opmerkingen: '',
      requiredRule: [(v) => !!v || 'Veld is verplicht'],
      errorMessage: null,
      valid: false,
      resultaat: null,
      opmerkingTypes: ['Tips', 'Klacht', 'Opmerking'],
      loading: false,
    };
  },
  computed: {
    klantData: function () {
      return this.$store.getters.klantData;
    },
  },
  created: function () {
    this.$store.dispatch('updateKlantData');
  },

  methods: {
    verstuurOpmerking: function () {
      this.loading = true;
      let adres = this.adres;
      let type = this.type;
      let opmerkingen = this.opmerkingen;
      let data = { adres, type, opmerkingen };

      nieuweOpmerking(data)
        .then((resp) => {
          this.resultaat = resp.result;
        })
        .catch((err) => {
          this.errorMessage = err;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
